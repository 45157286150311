.blinking-simulation-record {
  animation: simulationRecordBlink infinite 5s linear;
}

@keyframes simulationRecordBlink {
  0% {
    fill: #f53838;
  }
  45% {
    fill: #6c0303;
  }
  55% {
    fill: #6c0303;
  }
  100% {
    fill: #f53838;
  }
}
